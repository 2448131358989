<template>
  <div id="mapa-area-concessao"></div>
</template>

<script>
import L from 'leaflet';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import GeoServerService from '@/services/GeoServerService';
import mapFeaturesStylesMixins from '@/mixins/mapFeaturesStylesMixins';
import mapConfigMixins from '@/mixins/mapConfigMixins';
import filtersMixins from '@/mixins/geoserver/filtersMixins';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);

export default {
  mixins: [mapFeaturesStylesMixins, mapConfigMixins, filtersMixins],

  props: {
    month: {
      type: String,
      default: null
    },
    bdgdVersion: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      mapaId: 'mapa-area-concessao',
      map: null,
      mapZoom: 7,
      layerPlotado: null,
      aratFeatures: 0
    };
  },

  mounted() {
    this.desenhaMapa();
  },

  computed: {
    companyId() {
      return this.$store.getters.getCompanyId;
    },

    geoserverLayer() {
      return 'view_arat';
    },

    geoserverFields() {
      const fields = ['cod_id', 'fun_pr', 'fun_te', 'descr', 'geom'];
      return fields.join(',');
    },

    geoserverCqlFilter() {
      if (this.month && this.bdgdVersion) {
        return this.getBasicCqlFilter(
          this.companyId,
          this.month,
          this.bdgdVersion
        );
      }
      return null;
    },

    geoserverOgcFilter() {
      if (this.month && this.bdgdVersion) {
        return this.getBasicOgcFilter(
          this.companyId,
          this.month,
          this.bdgdVersion
        );
      }
      return null;
    }
  },

  methods: {
    desenhaMapa() {
      this.configuraMapa(this.mapZoom);

      const [longitude, latitude] =
        this.$store.getters.getCompanyCapitalCoordinates;

      this.map.setView([latitude, longitude], this.mapZoom);
    },

    configuraMapa(zoom) {
      this.map = L.map(this.mapaId, {
        fullscreenControl: true,
        loadingControl: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });

      L.control.layers(mapboxConfig.getBaseLayers()).addTo(this.map);
    },

    getDadosLayer() {
      this.map.spin(true);
      return GeoServerService.getDadosLayer(
        this.geoserverLayer,
        this.geoserverFields,
        this.isCqlFilter() ? this.geoserverCqlFilter : this.geoserverOgcFilter,
        '', // bbox
        5000, // maxFeatures
        'application/json', // outputFormat
        this.getFilterType()
      );
    },

    async plotaLayerMapa() {
      this.removeLayerMapa();

      const response = await this.getDadosLayer();
      const geojson = response.data;
      this.plotaLayer(geojson);
    },

    removeLayerMapa() {
      if (this.layerPlotado) {
        this.map.removeLayer(this.layerPlotado);
      }
    },

    plotaLayer(geojson) {
      this.aratFeatures = geojson.numberReturned;

      if (!geojson.numberReturned) {
        this.map.spin(false);
        return;
      }

      let layer = L.geoJSON(geojson, {
        style: () => {
          const color = this.getMapFeatureColor;
          const fillColor = this.getMapFeatureFillColor;
          const opacity = this.getMapFeatureOpacity;
          const fillOpacity = this.getMapFeatureFillOpacity;
          const weight = this.getMapFeatureWeight;

          return {
            color,
            fillColor,
            opacity,
            fillOpacity,
            weight
          };
        },

        onEachFeature: (feature, layer) => {
          const msgPopup = this.msgPopup(feature.properties);
          if (msgPopup) {
            layer.bindPopup(msgPopup);
          }
        }
      });

      layer.addTo(this.map);
      this.map.fitBounds(layer.getBounds());

      this.layerPlotado = layer;

      this.map.spin(false);
    },

    msgPopup(linha) {
      let msg = '<h4>ARAT</h4>';

      Object.keys(linha).forEach((campo) => {
        const dado = linha[campo];
        if (campo === 'geom' || dado === '' || dado === null) return;
        msg += `<b>${campo}:</b> ${linha[campo]}<br>`;
      });

      return msg;
    }
  },

  watch: {
    geoserverCqlFilter() {
      this.plotaLayerMapa();
    },

    aratFeatures() {
      this.$emit('aratFeaturesChanged', this.aratFeatures);
    }
  }
};
</script>

<style>
#mapa-area-concessao {
  height: 300px;
  z-index: 0;
}
</style>
